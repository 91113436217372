import { FSharpRef, Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { record_type, int32_type, option_type, lambda_type, union_type, array_type, class_type, string_type, bool_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { Deferred$1, AsyncMsg$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { AccountGroup, ReportAccounts, GlobalAccount_get_Default, ReportAccounts_$reflection, Account_$reflection, AccountGroup_$reflection, GlobalAccount_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { defaultArg } from "../fable_modules/fable-library.4.10.0/Option.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.4.10.0/String.js";
import { Typeahead_render, Typeahead_init, Typeahead_setFocus, Typeahead_setInputValue } from "./Typeahead.fs.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { map as map_1, collect as collect_1, empty, singleton, append, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { ofArray, singleton as singleton_1 } from "../fable_modules/fable-library.4.10.0/List.js";
import { where, append as append_1, map, choose, collect } from "../fable_modules/fable-library.4.10.0/Array.js";
import { Array_distinct } from "../fable_modules/fable-library.4.10.0/Seq2.js";
import { createObj, safeHash, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Toast_text, Toast_title, Toast_error } from "./SweetAlert.fs.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { tryParse } from "../fable_modules/fable-library.4.10.0/Guid.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { render as render_1 } from "./Loader.fs.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(IsModalActive, SearchAccountGroupsAsync, AccountGroupsDeferred, GetGlobalAccountAsync, GlobalAccountDeferred, PreviousSelectedAccountGroup, SelectedAccountGroup, SelectedAccounts, TotalAccounts, IsClient) {
        super();
        this.IsModalActive = IsModalActive;
        this.SearchAccountGroupsAsync = SearchAccountGroupsAsync;
        this.AccountGroupsDeferred = AccountGroupsDeferred;
        this.GetGlobalAccountAsync = GetGlobalAccountAsync;
        this.GlobalAccountDeferred = GlobalAccountDeferred;
        this.PreviousSelectedAccountGroup = PreviousSelectedAccountGroup;
        this.SelectedAccountGroup = SelectedAccountGroup;
        this.SelectedAccounts = SelectedAccounts;
        this.TotalAccounts = (TotalAccounts | 0);
        this.IsClient = IsClient;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.AccountSearch.AccountSearch.State", [], State, () => [["IsModalActive", bool_type], ["SearchAccountGroupsAsync", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(class_type("CView.UI.Models.IDataSourceItem")), string_type], FSharpResult$2, () => [[["ResultValue", array_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["ErrorValue", string_type]]])]))], ["AccountGroupsDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(class_type("CView.UI.Models.IDataSourceItem")), string_type], FSharpResult$2, () => [[["ResultValue", array_type(class_type("CView.UI.Models.IDataSourceItem"))]], [["ErrorValue", string_type]]]))], ["GetGlobalAccountAsync", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]])]))], ["GlobalAccountDeferred", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]]))], ["PreviousSelectedAccountGroup", option_type(AccountGroup_$reflection())], ["SelectedAccountGroup", option_type(AccountGroup_$reflection())], ["SelectedAccounts", array_type(Account_$reflection())], ["TotalAccounts", int32_type], ["IsClient", bool_type]]);
}

export class Intent extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SelectedAccountsChanged", "NoOp"];
    }
}

export function Intent_$reflection() {
    return union_type("CView.UI.AccountSearch.AccountSearch.Intent", [], Intent, () => [[["Item1", array_type(Account_$reflection())], ["Item2", int32_type], ["Item3", ReportAccounts_$reflection()]], []]);
}

function toGlobalAccount(state) {
    const matchValue = state.GlobalAccountDeferred;
    let matchResult, globalAccount;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 0) {
            matchResult = 0;
            globalAccount = matchValue.fields[0].fields[0];
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return globalAccount;
        default:
            return GlobalAccount_get_Default();
    }
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedAccountGroup", "SetSelectedAccounts", "GetGlobalAccount", "Cancel", "Select", "SetTotalAccountsToSelect"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.AccountSearch.AccountSearch.Msg", [], Msg, () => [[["Item", class_type("CView.UI.Models.IDataSourceItem")]], [["Item", array_type(Account_$reflection())]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [GlobalAccount_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", GlobalAccount_$reflection()]], [["ErrorValue", string_type]]]))]], [], [], [["Item", int32_type]]]);
}

export function init(accountGroup, previousAccountGroup, accounts, totalAccounts, searchAccountGroupsAsync, getGlobalAccountAsync, isClient) {
    let input_1, input_3;
    const name = defaultArg((input_1 = previousAccountGroup, (input_1 == null) ? void 0 : input_1.Name), "");
    const globalAccountId = defaultArg((input_3 = previousAccountGroup, (input_3 == null) ? void 0 : input_3.Id), "00000000-0000-0000-0000-000000000000");
    if (!isClient && !isNullOrWhiteSpace(name)) {
        Typeahead_setInputValue("account", name);
    }
    else if (!isClient && isNullOrWhiteSpace(name)) {
        Typeahead_setFocus("account");
    }
    const command = Cmd_batch(toList(delay(() => append(singleton(Typeahead_init("account", searchAccountGroupsAsync, (Item) => (new Msg(0, [Item])), 500, 1)), delay(() => ((globalAccountId !== "00000000-0000-0000-0000-000000000000") ? singleton(singleton_1((dispatch) => {
        dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
    })) : empty()))))));
    return [new State(accountGroup == null, searchAccountGroupsAsync, new Deferred$1(0, []), getGlobalAccountAsync, new Deferred$1(0, []), previousAccountGroup, accountGroup, accounts, totalAccounts, isClient), command];
}

export function update(msg, state) {
    let input_1, input_7, input_3, matchValue_3, accountGroup_6, outArg;
    switch (msg.tag) {
        case 1:
            return [new State(state.IsModalActive, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.PreviousSelectedAccountGroup, state.SelectedAccountGroup, msg.fields[0], state.TotalAccounts, state.IsClient), Cmd_none(), new Intent(1, [])];
        case 3: {
            Typeahead_setInputValue("account", defaultArg((input_1 = state.PreviousSelectedAccountGroup, (input_1 == null) ? void 0 : input_1.Name), ""));
            return [new State(false, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.PreviousSelectedAccountGroup, void 0, new Array(0), state.TotalAccounts, state.IsClient), Cmd_none(), new Intent(1, [])];
        }
        case 4: {
            const globalAccount = toGlobalAccount(state);
            let isAllAccountSelected;
            const array_2 = collect((parentAccounts) => parentAccounts.Accounts, globalAccount.Accounts);
            isAllAccountSelected = array_2.every((account) => state.SelectedAccounts.some((ads) => (ads.Id === account.Id)));
            const regionalAccountsSelected = choose((regionalAccount) => {
                if (regionalAccount.Accounts.every((account_1) => state.SelectedAccounts.some((selectedAccount) => (selectedAccount.Id === account_1.Id)))) {
                    return regionalAccount.ExternalId;
                }
                else {
                    return void 0;
                }
            }, globalAccount.Accounts);
            const remainingAccounts = choose((account_2) => {
                if (account_2.GlobalAccountGroupId !== globalAccount.Id) {
                    return void 0;
                }
                else {
                    return account_2.ExternalId;
                }
            }, state.SelectedAccounts);
            const reportAccounts = new ReportAccounts(globalAccount.ExternalId, isAllAccountSelected ? map((x_1) => x_1.ExternalId, globalAccount.Accounts) : regionalAccountsSelected, isAllAccountSelected ? map((x_3) => x_3.ExternalId, collect((x_2) => x_2.Accounts, globalAccount.Accounts)) : remainingAccounts);
            const selectedAccounts = Array_distinct(state.SelectedAccounts.filter((x_4) => (x_4.GlobalAccountGroupId === globalAccount.Id)), {
                Equals: equals,
                GetHashCode: safeHash,
            });
            return [new State(false, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.PreviousSelectedAccountGroup, state.SelectedAccountGroup, state.SelectedAccounts, state.TotalAccounts, state.IsClient), Cmd_none(), new Intent(0, [selectedAccounts, state.TotalAccounts, reportAccounts])];
        }
        case 2:
            if (msg.fields[0].tag === 1) {
                let command_2;
                const input_5 = msg.fields[0].fields[0];
                command_2 = ((input_5.tag === 1) ? Toast_error(Toast_title("Global Account", Toast_text(input_5.fields[0]))) : Cmd_none());
                if ((state.SelectedAccountGroup == null) && !state.IsClient) {
                    Typeahead_setFocus("account");
                }
                else {
                    Typeahead_setInputValue("account", defaultArg((input_7 = state.SelectedAccountGroup, (input_7 == null) ? void 0 : input_7.Name), ""));
                }
                return [new State(state.IsModalActive, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, new Deferred$1(2, [msg.fields[0].fields[0]]), state.PreviousSelectedAccountGroup, state.SelectedAccountGroup, state.SelectedAccounts, state.TotalAccounts, state.IsClient), command_2, new Intent(1, [])];
            }
            else if (equals(state.GlobalAccountDeferred, new Deferred$1(1, []))) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else if (!state.IsModalActive) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else if ((state.SelectedAccountGroup == null) && (state.PreviousSelectedAccountGroup == null)) {
                return [state, Cmd_none(), new Intent(1, [])];
            }
            else {
                return [new State(state.IsModalActive, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, new Deferred$1(1, []), state.PreviousSelectedAccountGroup, state.SelectedAccountGroup, state.SelectedAccounts, state.TotalAccounts, state.IsClient), Cmd_OfAsyncWith_perform((computation) => {
                    startImmediate(computation);
                }, state.GetGlobalAccountAsync, defaultArg((input_3 = ((matchValue_3 = state.SelectedAccountGroup, (state.PreviousSelectedAccountGroup != null) ? ((matchValue_3 != null) ? ((matchValue_3.Id === "00000000-0000-0000-0000-000000000000") ? ((accountGroup_6 = matchValue_3, state.PreviousSelectedAccountGroup)) : state.SelectedAccountGroup) : state.PreviousSelectedAccountGroup) : ((matchValue_3 != null) ? ((matchValue_3.Id === "00000000-0000-0000-0000-000000000000") ? ((accountGroup_6 = matchValue_3, state.PreviousSelectedAccountGroup)) : state.SelectedAccountGroup) : state.SelectedAccountGroup))), (input_3 == null) ? void 0 : input_3.Id), "00000000-0000-0000-0000-000000000000"), (arg_1) => (new Msg(2, [new AsyncMsg$1(1, [arg_1])]))), new Intent(1, [])];
            }
        case 5:
            return [new State(state.IsModalActive, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, state.GlobalAccountDeferred, state.PreviousSelectedAccountGroup, state.SelectedAccountGroup, state.SelectedAccounts, msg.fields[0], state.IsClient), Cmd_none(), new Intent(1, [])];
        default: {
            const accountGroup_2 = !isNullOrWhiteSpace(msg.fields[0].Id) ? (new AccountGroup(((outArg = "00000000-0000-0000-0000-000000000000", [tryParse(msg.fields[0].Id, new FSharpRef(() => outArg, (v) => {
                outArg = v;
            })), outArg]))[1], msg.fields[0].DisplayValue)) : void 0;
            let isCurrentAccountGroup;
            const matchValue = state.SelectedAccountGroup;
            let matchResult, accountGroup_3, s1;
            if (matchValue != null) {
                if (accountGroup_2 != null) {
                    matchResult = 0;
                    accountGroup_3 = accountGroup_2;
                    s1 = matchValue;
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0: {
                    isCurrentAccountGroup = (s1.Id === accountGroup_3.Id);
                    break;
                }
                default:
                    isCurrentAccountGroup = true;
            }
            const command = (accountGroup_2 == null) ? Cmd_none() : singleton_1((dispatch) => {
                dispatch(new Msg(2, [new AsyncMsg$1(0, [])]));
            });
            return [new State(state.IsModalActive, state.SearchAccountGroupsAsync, state.AccountGroupsDeferred, state.GetGlobalAccountAsync, (accountGroup_2 == null) ? state.GlobalAccountDeferred : (new Deferred$1(0, [])), state.PreviousSelectedAccountGroup, accountGroup_2, isCurrentAccountGroup ? state.SelectedAccounts : (new Array(0)), state.TotalAccounts, state.IsClient), command, new Intent(1, [])];
        }
    }
}

export function render(state, dispatch) {
    let elems_13, elems_12, elems, elems_10, elems_3, elems_2, elems_1, elems_9, elems_8, elems_7, elems_11;
    return createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("modal"), delay(() => append(singleton("px-4"), delay(() => (state.IsModalActive ? singleton("is-active") : empty()))))))))], (elems_13 = [createElement("div", {
        className: "modal-background",
    }), createElement("div", createObj(ofArray([["className", join(" ", ["modal-card", "pb-6"])], (elems_12 = [createElement("header", createObj(ofArray([["className", "modal-card-head"], (elems = [createElement("p", {
        className: join(" ", ["modal-card-title", "is-size-6"]),
        children: "Select accounts",
    }), createElement("span", {
        className: join(" ", ["material-icons"]),
        children: "check",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("section", createObj(ofArray([["className", "modal-card-body"], ["style", createObj(toList(delay(() => ((!equals(state.GlobalAccountDeferred, new Deferred$1(0, [])) && !state.IsClient) ? singleton(["minHeight", 350 + "px"]) : empty()))))], (elems_10 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton("column"), delay(() => (state.IsClient ? singleton("is-hidden") : empty()))))))], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["field", "has-addons", "has-addons-right"])], (elems_1 = [Typeahead_render("account", "Global Account Name")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("hr", {
        className: join(" ", toList(delay(() => (state.IsClient ? singleton("is-hidden") : empty())))),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["columns"])], (elems_9 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_8 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-bordered", "p-2"])], (elems_7 = toList(delay(() => {
        let elems_4;
        const matchValue = state.GlobalAccountDeferred;
        switch (matchValue.tag) {
            case 1:
                return singleton(render_1());
            case 2:
                if (matchValue.fields[0].tag === 0) {
                    const parentAccounts = matchValue.fields[0].fields[0].Accounts;
                    const childAccounts = collect((x) => x.Accounts, parentAccounts);
                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "my-1"])], (elems_4 = [createElement("input", {
                        style: {
                            minWidth: 20 + "px",
                        },
                        className: join(" ", ["is-clickable", "mr-3"]),
                        id: matchValue.fields[0].fields[0].Id,
                        name: matchValue.fields[0].fields[0].Id,
                        type: "checkbox",
                        checked: childAccounts.every((account) => state.SelectedAccounts.some((ads) => (ads.Id === account.Id))),
                        onChange: (ev) => {
                            dispatch(new Msg(1, [ev.target.checked ? childAccounts : (new Array(0))]));
                            dispatch(new Msg(5, [childAccounts.length]));
                        },
                    }), createElement("label", {
                        className: join(" ", ["is-clickable", "mr-3", "is-size-7"]),
                        for: matchValue.fields[0].fields[0].Id,
                        children: matchValue.fields[0].fields[0].Name,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => collect_1((parentAccount) => {
                        let elems_5;
                        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "my-1", "ml-5"])], (elems_5 = [createElement("input", {
                            style: {
                                minWidth: 20 + "px",
                            },
                            className: join(" ", ["is-clickable", "mr-3"]),
                            id: parentAccount.Id,
                            name: parentAccount.Id,
                            type: "checkbox",
                            checked: parentAccount.Accounts.every((account_1) => state.SelectedAccounts.some((ads_1) => (ads_1.Id === account_1.Id))),
                            onChange: (ev_1) => {
                                dispatch(new Msg(1, [ev_1.target.checked ? append_1(parentAccount.Accounts, state.SelectedAccounts) : where((ads_2) => !parentAccount.Accounts.some((x_6) => (x_6.Id === ads_2.Id)), state.SelectedAccounts)]));
                                dispatch(new Msg(5, [childAccounts.length]));
                            },
                        }), createElement("label", {
                            className: join(" ", ["is-clickable", "mr-3", "is-size-7"]),
                            for: parentAccount.Id,
                            children: parentAccount.Name,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => map_1((account_2) => {
                            let elems_6;
                            return createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center", "my-1", "ml-6"])], (elems_6 = [createElement("input", {
                                style: {
                                    minWidth: 20 + "px",
                                },
                                className: join(" ", ["is-clickable", "mr-3"]),
                                id: account_2.Id,
                                name: account_2.Id,
                                type: "checkbox",
                                checked: state.SelectedAccounts.some((ads_3) => (ads_3.Id === account_2.Id)),
                                onChange: (ev_2) => {
                                    dispatch(new Msg(1, [ev_2.target.checked ? append_1([account_2], state.SelectedAccounts) : where((x_12) => (x_12.Id !== account_2.Id), state.SelectedAccounts)]));
                                    dispatch(new Msg(5, [childAccounts.length]));
                                },
                            }), createElement("label", {
                                className: join(" ", ["is-clickable", "mr-3", "is-size-7"]),
                                for: account_2.Id,
                                children: account_2.Name,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
                        }, Array_distinct(where((x_8) => (x_8.AccountGroupId === parentAccount.Id), childAccounts), {
                            Equals: equals,
                            GetHashCode: safeHash,
                        }))));
                    }, parentAccounts)));
                }
                else {
                    return singleton(defaultOf());
                }
            default:
                return singleton(createElement("div", {
                    className: join(" ", ["is-size-7", "p-4"]),
                    children: "No global account selected",
                }));
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("footer", createObj(ofArray([["className", join(" ", ["modal-card-foot", "is-justify-content-center"])], (elems_11 = [createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth", "is-primary"]),
        type: "button",
        children: "Select",
        disabled: state.SelectedAccounts.length === 0,
        onClick: (_arg) => {
            dispatch(new Msg(4, []));
        },
    }), createElement("button", {
        className: join(" ", ["button", "is-small", "is-fullwidth"]),
        type: "button",
        children: "Cancel",
        onClick: (_arg_1) => {
            dispatch(new Msg(3, []));
        },
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])));
}

