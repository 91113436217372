import { Union, Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { ContainerItem_$reflection, User_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { record_type, union_type, string_type, class_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { DateTimeOffset_formatNullable, AsyncMsg$1, Deferred$1, AsyncMsg$1_$reflection, Deferred$1_$reflection } from "../Extensions.fs.js";
import { SelectableTabs, SelectableTabs_$reflection } from "../Domain/Container.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.4.10.0/List.js";
import { Alert_text, Alert_title, Alert_error } from "../Components/SweetAlert.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { comparePrimitives, createObj, equals } from "../fable_modules/fable-library.4.10.0/Util.js";
import { Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.4.0.2/./cmd.fs.js";
import { startImmediate } from "../fable_modules/fable-library.4.10.0/Async.js";
import { find } from "../Api/ContainerApi.fs.js";
import { createElement } from "react";
import { printf, join } from "../fable_modules/fable-library.4.10.0/String.js";
import { map, empty, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.4.10.0/Seq.js";
import { render as render_1 } from "../Components/Loader.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/./Interop.fs.js";
import { formatDecimalWithUnits, formatDecimal, formatInt } from "../Domain/Formatter.fs.js";
import { IconType, ModeOfTransportIcon_render } from "../Components/ModeOfTransportIcon.fs.js";
import { sortBy } from "../fable_modules/fable-library.4.10.0/Array.js";
import { value as value_380 } from "../fable_modules/fable-library.4.10.0/Option.js";
import { minValue } from "../fable_modules/fable-library.4.10.0/DateOffset.js";
import { compare } from "../fable_modules/fable-library.4.10.0/Date.js";
import { defaultOf } from "../fable_modules/Feliz.2.6.0/../fable-library.4.10.0/Util.js";

export class State extends Record {
    constructor(User, ContainerId, ShipmentId, Container, ActiveTab) {
        super();
        this.User = User;
        this.ContainerId = ContainerId;
        this.ShipmentId = ShipmentId;
        this.Container = Container;
        this.ActiveTab = ActiveTab;
    }
}

export function State_$reflection() {
    return record_type("CView.UI.Pages.ContainerDetails.State", [], State, () => [["User", User_$reflection()], ["ContainerId", class_type("System.Guid")], ["ShipmentId", class_type("System.Guid")], ["Container", Deferred$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ContainerItem_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ContainerItem_$reflection()]], [["ErrorValue", string_type]]]))], ["ActiveTab", SelectableTabs_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetActiveTab", "GetContainer"];
    }
}

export function Msg_$reflection() {
    return union_type("CView.UI.Pages.ContainerDetails.Msg", [], Msg, () => [[["Item", SelectableTabs_$reflection()]], [["Item", AsyncMsg$1_$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [ContainerItem_$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", ContainerItem_$reflection()]], [["ErrorValue", string_type]]]))]]]);
}

export function init(containertId, shipmentId, user) {
    return [new State(user, containertId, shipmentId, new Deferred$1(0, []), new SelectableTabs(1, [])), singleton((dispatch) => {
        dispatch(new Msg(1, [new AsyncMsg$1(0, [])]));
    })];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.User, state.ContainerId, state.ShipmentId, new Deferred$1(2, [msg.fields[0].fields[0]]), state.ActiveTab), (msg.fields[0].fields[0].tag === 1) ? Alert_error(Alert_title("Get Container", Alert_text(msg.fields[0].fields[0].fields[0]))) : Cmd_none()];
        }
        else if (equals(state.Container, new Deferred$1(1, []))) {
            return [state, Cmd_none()];
        }
        else {
            return [new State(state.User, state.ContainerId, state.ShipmentId, new Deferred$1(1, []), state.ActiveTab), Cmd_OfAsyncWith_perform((computation) => {
                startImmediate(computation);
            }, () => find(state.ContainerId, state.ShipmentId), void 0, (arg) => (new Msg(1, [new AsyncMsg$1(1, [arg])])))];
        }
    }
    else {
        return [new State(state.User, state.ContainerId, state.ShipmentId, state.Container, msg.fields[0]), Cmd_none()];
    }
}

export function render(state, dispatch) {
    let elems_43;
    return createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_43 = toList(delay(() => {
        let elems_3, elems_2, children, elems, elems_1;
        const matchValue_1 = state.Container;
        return (matchValue_1.tag === 1) ? singleton_1(render_1()) : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 0) ? append(singleton_1(createElement("nav", createObj(ofArray([["aria-label", "breadcrumbs"], ["className", join(" ", ["breadcrumb", "is-small", "box", "is-flex", "is-flex-wrap-wrap", "is-justify-content-space-between", "is-align-items-center"])], (elems_3 = [createElement("ul", createObj(ofArray([["className", "has-text-weight-semibold"], (elems_2 = [(children = singleton(createElement("a", {
            className: "is-size-6",
            href: RouterModule_encodeParts(singleton("/shipments"), 1),
            children: "Shipments",
        })), createElement("li", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })), createElement("li", createObj(ofArray([["className", "is-active"], (elems = [createElement("a", {
            className: "is-size-6",
            children: "Container",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("li", createObj(ofArray([["className", join(" ", ["is-active", "is-size-6"])], (elems_1 = [createElement("a", {
            children: matchValue_1.fields[0].fields[0].Number,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
            let container_1, elems_24, elems_23, elems_18, elems_17, elems_6, elems_5, elems_4, elems_8, elems_7, elems_10, elems_9, elems_12, elems_11, elems_14, elems_13, elems_16, elems_15, elems_22, elems_19, elems_21, elems_20, children_8, children_2, children_4, children_6;
            return append(singleton_1((container_1 = matchValue_1.fields[0].fields[0], createElement("div", createObj(ofArray([["className", "box"], (elems_24 = [createElement("div", createObj(ofArray([["className", "columns"], (elems_23 = [createElement("div", createObj(ofArray([["className", join(" ", ["column"])], (elems_18 = [createElement("nav", createObj(ofArray([["className", "level"], (elems_17 = [createElement("div", createObj(ofArray([["className", join(" ", ["level-item", "has-text-centered"])], (elems_6 = [createElement("div", createObj(singleton((elems_5 = [createElement("p", {
                className: "heading",
                children: "Container",
            }), createElement("p", {
                className: join(" ", ["title", "is-size-4"]),
                children: container_1.Number,
            }), createElement("p", createObj(singleton((elems_4 = [createElement("span", {
                className: join(" ", ["has-text-weight-semibold", "is-size-6"]),
                children: "Seal",
            }), createElement("span", {
                className: join(" ", ["ml-1", "is-size-6"]),
                children: container_1.SealNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["level-item", "has-text-centered"])], (elems_8 = [createElement("div", createObj(singleton((elems_7 = [createElement("p", {
                className: "heading",
                children: "Load Type",
            }), createElement("p", {
                className: join(" ", ["title", "is-size-4"]),
                children: container_1.LoadType,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["level-item", "has-text-centered"])], (elems_10 = [createElement("div", createObj(singleton((elems_9 = [createElement("p", {
                className: "heading",
                children: "Container Type",
            }), createElement("p", {
                className: join(" ", ["title", "is-size-4"]),
                children: container_1.ContainerType,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["level-item", "has-text-centered"])], (elems_12 = [createElement("div", createObj(singleton((elems_11 = [createElement("p", {
                className: "heading",
                children: "Pieces",
            }), createElement("p", {
                className: join(" ", ["title", "is-size-4"]),
                children: formatInt(container_1.TotalPieces),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["level-item", "has-text-centered"])], (elems_14 = [createElement("div", createObj(singleton((elems_13 = [createElement("p", {
                className: "heading",
                children: "Volume",
            }), createElement("p", {
                className: join(" ", ["title", "is-size-4"]),
                children: formatDecimal(container_1.TotalVolume),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["level-item", "has-text-centered"])], (elems_16 = [createElement("div", createObj(singleton((elems_15 = [createElement("p", {
                className: "heading",
                children: "Weight",
            }), createElement("p", {
                className: join(" ", ["title", "is-size-4"]),
                children: formatDecimalWithUnits(container_1.TotalChargeableWeightUnit, container_1.TotalChargeableWeight),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["column", "is-narrow"])], (elems_22 = [createElement("div", createObj(ofArray([["className", join(" ", ["is-flex", "is-align-items-center"])], (elems_19 = [ModeOfTransportIcon_render(new IconType(0, []), container_1.ModeOfTransportIcon), createElement("a", {
                className: join(" ", ["is-link"]),
                href: RouterModule_encodeParts(ofArray(["/shipment-details", container_1.ShipmentId]), 1),
                children: container_1.ShipmentNumber,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("div", createObj(ofArray([["className", "mt-1"], (elems_21 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_20 = [(children_8 = ofArray([(children_2 = ofArray([createElement("th", {
                style: {
                    border: (((0 + "px ") + "none") + " ") + "transparent",
                    padding: 0 + "rem",
                    width: 50 + "px",
                },
                children: "Carrier",
            }), createElement("td", {
                style: {
                    border: (((0 + "px ") + "none") + " ") + "transparent",
                    padding: 0 + "rem",
                },
                children: container_1.CarrierName,
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            })), (children_4 = ofArray([createElement("th", {
                style: {
                    border: (((0 + "px ") + "none") + " ") + "transparent",
                    padding: 0 + "rem",
                    width: 50 + "px",
                },
                children: "Vessel",
            }), createElement("td", {
                style: {
                    border: (((0 + "px ") + "none") + " ") + "transparent",
                    padding: 0 + "rem",
                },
                children: container_1.Vessel,
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_4)),
            })), (children_6 = ofArray([createElement("th", {
                style: {
                    border: (((0 + "px ") + "none") + " ") + "transparent",
                    padding: 0 + "rem",
                    width: 50 + "px",
                },
                children: "Voyage",
            }), createElement("td", {
                style: {
                    border: (((0 + "px ") + "none") + " ") + "transparent",
                    padding: 0 + "rem",
                },
                children: container_1.Voyage,
            })]), createElement("tr", {
                children: Interop_reactApi.Children.toArray(Array.from(children_6)),
            }))]), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_21))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_24))])]))))), delay(() => {
                let container_2, elems_42, elems_40, children_18, elems_41;
                return singleton_1((container_2 = matchValue_1.fields[0].fields[0], createElement("div", createObj(ofArray([["className", join(" ", ["box"])], (elems_42 = [createElement("div", createObj(ofArray([["className", join(" ", ["tabs", "is-small", "is-boxed"])], (elems_40 = [(children_18 = toList(delay(() => {
                    let elems_38, fmt;
                    return append(singleton_1(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(1, [])) ? singleton_1("is-active") : empty()))))))))], (elems_38 = [createElement("a", createObj(ofArray([["onClick", (_arg_2) => {
                        dispatch(new Msg(0, [new SelectableTabs(1, [])]));
                    }], ((fmt = printf("Transit Events (%i)"), fmt.cont((value_363) => ["children", value_363])))(container_2.ContainerEvents.length)])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_38))])])))), delay(() => {
                        let elems_39, fmt_1;
                        return state.User.CanViewInventory ? singleton_1(createElement("li", createObj(ofArray([["className", join(" ", toList(delay(() => append(singleton_1("has-text-weight-semibold"), delay(() => append(singleton_1("tablink"), delay(() => (equals(state.ActiveTab, new SelectableTabs(0, [])) ? singleton_1("is-active") : empty()))))))))], (elems_39 = [createElement("a", createObj(ofArray([["onClick", (_arg_3) => {
                            dispatch(new Msg(0, [new SelectableTabs(0, [])]));
                        }], ((fmt_1 = printf("Purchase Order Line Items (%i)"), fmt_1.cont((value_369) => ["children", value_369])))(container_2.PurchaseOrderLineItems.length)])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_39))])])))) : empty();
                    }));
                })), createElement("ul", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_18)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_40))])]))), createElement("div", createObj(ofArray([["className", "tabcontent"], (elems_41 = toList(delay(() => {
                    let containerEvents, elems_37, purchaseOrderLineItems, elems_32;
                    return (state.ActiveTab.tag === 1) ? singleton_1((containerEvents = container_2.ContainerEvents, createElement("div", createObj(ofArray([["className", "mx-4"], (elems_37 = toList(delay(() => {
                        let elems_36, elems_35, children_16, children_14, elems_34;
                        return (containerEvents.length === 0) ? singleton_1(createElement("div", {
                            className: "has-text-centered",
                            children: "No transit events were found for this container",
                        })) : singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                            maxHeight: 450 + "px",
                            overflowY: "auto",
                        }], (elems_36 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_35 = [(children_16 = singleton((children_14 = ofArray([createElement("th", {
                            className: "p-2",
                            children: "Code",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Event",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Date",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Provided By",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Location",
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_14)),
                        }))), createElement("thead", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_16)),
                        })), createElement("tbody", createObj(ofArray([["className", ""], (elems_34 = toList(delay(() => map((containerEvent) => {
                            let elems_33;
                            return createElement("tr", createObj(singleton((elems_33 = [createElement("td", {
                                className: "p-2",
                                children: containerEvent.EventCode,
                            }), createElement("td", {
                                className: "p-2",
                                children: containerEvent.EventName,
                            }), createElement("td", {
                                className: "p-2",
                                children: DateTimeOffset_formatNullable(containerEvent.EventDateTime),
                            }), createElement("td", {
                                className: "p-2",
                                children: containerEvent.PortName,
                            }), createElement("td", {
                                className: "p-2",
                                children: containerEvent.Location,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_33))]))));
                        }, sortBy((containerEvent_1) => {
                            const _arg_1 = containerEvent_1.EventDateTime != null;
                            if (_arg_1) {
                                return value_380(containerEvent_1.EventDateTime);
                            }
                            else {
                                return minValue();
                            }
                        }, containerEvents, {
                            Compare: compare,
                        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_34))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_35))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_36))])]))));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_37))])]))))) : singleton_1((purchaseOrderLineItems = container_2.PurchaseOrderLineItems, createElement("div", createObj(ofArray([["className", "mx-4"], (elems_32 = toList(delay(() => {
                        let elems_31, elems_30, children_12, children_10, elems_29;
                        return (purchaseOrderLineItems.length === 0) ? singleton_1(createElement("div", {
                            className: "has-text-centered",
                            children: "No purchase order line items were found for this container",
                        })) : singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["table-container", "table-has-fixed-header"])], ["style", {
                            maxHeight: 450 + "px",
                            overflowY: "auto",
                        }], (elems_31 = [createElement("table", createObj(ofArray([["className", join(" ", ["table", "is-narrow", "is-fullwidth"])], (elems_30 = [(children_12 = singleton((children_10 = ofArray([createElement("th", {
                            className: "p-2",
                            children: "Box Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Packing List Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Purchase Order",
                        }), createElement("th", {
                            className: "p-2",
                            children: "PO Item Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "MR Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "MR Item",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Vendor",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Part Number",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Description",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Quantity Received",
                        }), createElement("th", {
                            className: "p-2",
                            children: "Promise Date",
                        })]), createElement("tr", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_10)),
                        }))), createElement("thead", {
                            children: Interop_reactApi.Children.toArray(Array.from(children_12)),
                        })), createElement("tbody", createObj(ofArray([["className", ""], (elems_29 = toList(delay(() => map((purchaseOrderLineItem) => {
                            let elems_28, elems_25, elems_26, elems_27, _arg;
                            return createElement("tr", createObj(singleton((elems_28 = [createElement("td", {
                                className: "p-2",
                                children: purchaseOrderLineItem.BoxNumber,
                            }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_25 = [createElement("a", {
                                className: join(" ", ["is-link", "is-underlined"]),
                                href: RouterModule_encodeParts(ofArray(["/packing-list-details", purchaseOrderLineItem.PackingListId]), 1),
                                children: purchaseOrderLineItem.PackingListNumber,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_25))])]))), createElement("td", createObj(ofArray([["className", "p-2"], (elems_26 = [createElement("a", {
                                className: join(" ", ["is-link", "is-underlined"]),
                                href: RouterModule_encodeParts(ofArray(["/purchase-order-details", purchaseOrderLineItem.PurchaseOrderId]), 1),
                                children: purchaseOrderLineItem.PurchaseOrderNumber,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_26))])]))), createElement("td", {
                                className: "p-2",
                                children: formatInt(purchaseOrderLineItem.PurchaseOrderLineItemNumber),
                            }), createElement("td", createObj(ofArray([["className", "p-2"], (elems_27 = [createElement("a", {
                                className: join(" ", ["is-link", "is-underlined"]),
                                href: RouterModule_encodeParts(ofArray(["/material-receipt-details", (_arg = (purchaseOrderLineItem.MaterialReceiptId != null), _arg ? value_380(purchaseOrderLineItem.MaterialReceiptId) : "")]), 1),
                                children: purchaseOrderLineItem.MaterialReceiptNumber,
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_27))])]))), createElement("td", {
                                className: "p-2",
                                children: formatInt(purchaseOrderLineItem.MaterialReceiptItem),
                            }), createElement("td", {
                                className: "p-2",
                                children: purchaseOrderLineItem.VendorName,
                            }), createElement("td", {
                                className: "p-2",
                                children: purchaseOrderLineItem.PartNumber,
                            }), createElement("td", {
                                className: "p-2",
                                children: purchaseOrderLineItem.Description,
                            }), createElement("td", {
                                className: "p-2",
                                children: formatDecimalWithUnits(purchaseOrderLineItem.QuantityReceivedUnit, purchaseOrderLineItem.QuantityReceived),
                            }), createElement("td", {
                                className: "p-2",
                                children: DateTimeOffset_formatNullable(purchaseOrderLineItem.PromiseDate),
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_28))]))));
                        }, sortBy((purchaseOrderLineItem_1) => purchaseOrderLineItem_1.PurchaseOrderLineItemNumber, purchaseOrderLineItems, {
                            Compare: comparePrimitives,
                        })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_29))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_30))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_31))])]))));
                    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_32))])])))));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_41))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_42))])])))));
            }));
        })) : singleton_1(defaultOf())) : singleton_1(defaultOf()));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_43))])])));
}

