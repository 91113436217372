﻿/**
 * @typedef {Object} IDataSourceItem
 * @property {string} Id
 * @property {string} DisplayValue
 * @property {any} Value
 */

/**
 * 
 * @param {string} id - The text input element ID.
 * @param {string} menuId - The menu div element ID.
 * @param {(x: string) => Promise<IDataSourceItem[]>} getDataSource - Load the data source. 
 * @param {(x: IDataSourceItem) => void} onSelect - The callback to trigger when a data source item is selected.
 * @param {Number} delay - The debounce delay.
 * @param {Number} inputValueMinLength - The number of characters required before the data source is requested.
 */
export function typeahead(id, menuId, getDataSource, onSelect, delay = 100, inputValueMinLength = 1) {

    setTimeout(() => {
        const input = document.getElementById(id);
        const inputParentNode = input.parentNode;
        const menuEl = document.getElementById(menuId);
        menuEl.innerHTML = '<div class="dropdown-content"></div>';

        const debounce = function (func, timeout) {
            let debounceTimer;
            return function () {
                const context = this;
                const args = arguments;
                clearTimeout(debounceTimer);
                debounceTimer
                    = setTimeout(() => func.apply(context, args), timeout);
            }
        };

        const highlightMatchingValue = function (original, strReplace) {
            return original.split(' ')
                .map(value => value.replace(new RegExp(strReplace, 'i'), match => '<b>' + match + '</b>'))
                .join(' ');
        };

        const setLoadingIndicator = function (isLoading) {
            if (!inputParentNode) { return; }
            if (isLoading) {
                inputParentNode.classList.add('is-loading');
            } else {
                inputParentNode.classList.remove('is-loading');
            }
        };

        const setValue = function (e, data) {
          
            e.preventDefault();
            input.value = data?.DisplayValue ?? '';
            menuEl.style.display = 'none';
            if (onSelect) {
                onSelect({ Id: data?.Id, DisplayValue: data?.DisplayValue, Value: data?.Value });
            }
             
            return false;
        };

        const handleApi = function (e) {
            const inputValue = e.target.value;
            menuEl.style.display = 'none';
            menuEl.innerHTML = '<div class="dropdown-content"></div>';

            if (inputValue == '') {
                onSelect({ Id: '', DisplayValue: '', Value: '' });
            }

            if (inputValue.length <= inputValueMinLength) {
                return;
            }

            setLoadingIndicator(true);
            getDataSource(inputValue).then(function (suggestions) {                              
                const suggestionsEl = suggestions.map(function (suggestion) {
                    const a = document.createElement('a');
                    a.href = '#';
                    a.classList.add('dropdown-item');
                    a.innerHTML = highlightMatchingValue(suggestion.DisplayValue, inputValue);
                    a.dataset.value = suggestion.Id;
                    a.addEventListener('click', function (e) {
                        e.preventDefault();
                        setValue(e, suggestion)
                    });
                    return a;
                })

                suggestionsEl.forEach(function (suggestionEl) {
                    const existingEl = Array.from(menuEl.childNodes[0].children).find(
                        child => child.dataset.value === suggestionEl.dataset.value
                    );

                    if (!existingEl) {
                        menuEl.childNodes[0].appendChild(suggestionEl);
                    }
                });

                if (suggestions.length > 0) {
                    menuEl.style.display = 'block';
                }
            }).catch(function (err) {
                menuEl.innerHTML = '<div class="dropdown-content"><a class="dropdown-item has-text-danger has-text-centered has-text-weight-semibold is-size-7">Something really bad happened.</a></div>';
                menuEl.style.display = 'block';
                console.error(err);
            }).finally(function () { setLoadingIndicator(false); });
        };


        if (input) {

            const debouncedHandleApi = debounce(handleApi, delay);

            if (!input[id]) {
                input.addEventListener('input', (e) => { 
                    if (e.target.id === id) {
                        debouncedHandleApi(e);
                    }
                    
                });
                input.addEventListener('focusout', function (e) {
                    if (e.relatedTarget === null || !e.relatedTarget.classList.contains('dropdown-item')) {
                        menuEl.style.display = 'none';
                    }
                });
                input[id] = true;
            }

            // needed for inventory page that has search by functionality
            const observer = new MutationObserver((mutationsList) => {
                mutationsList.forEach((mutation) => {

                    if (mutation.type === 'attributes') {
                        input.value = '';
                        onSelect({ Id: '', DisplayValue: '', Value: '' });
                    }
                });
            });

            observer.observe(input, {
                attributes: true
            });


            window.addEventListener("hashchange", (event) => {
                input.value = '';
                observer.disconnect();
            });
        }


    }, 0);

};
