import { singleton } from "../fable_modules/fable-library.4.10.0/AsyncBuilder.js";
import { Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B } from "../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { Blob_download, Http_overrideResponseType, Headers_authorization, Headers_contentType, Http_header, Http_request, Http_method, Http_content, Http_send } from "../fable_modules/Fable.SimpleHttp.3.5.0/Http.fs.js";
import { ResponseTypes, HttpMethod, BodyContent } from "../fable_modules/Fable.SimpleHttp.3.5.0/Types.fs.js";
import { getIdToken } from "../Authentication.fs.js";
import { FSharpResult$2, Result_MapError } from "../fable_modules/fable-library.4.10.0/Choice.js";
import { some } from "../fable_modules/fable-library.4.10.0/Option.js";
import { SimpleJson_parseNative, SimpleJson_mapKeys } from "../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { String_capitalize } from "../../CView.DTOs/PrimitiveTypes.fs.js";
import { Convert_fromJson } from "../fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "../fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { PagedDataResponse$1_$reflection, BookingSearchItem_$reflection } from "../../CView.DTOs/DTOs.fs.js";
import { tryParseAsApiMessage } from "./HttpResponse.fs.js";

export function search(query) {
    return singleton.Delay(() => {
        let req_2, req_1;
        const payload = Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(query);
        return singleton.Bind(Http_send(Http_content(new BodyContent(1, [payload]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Bookings/Search`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2)))), (_arg) => {
            let input_1;
            const response = _arg;
            const result_1 = (response.statusCode === 200) ? Result_MapError((error) => {
                console.error(some("API error"), error);
                return "Something really bad happened. Please contact the system administrator.";
            }, (input_1 = SimpleJson_mapKeys(String_capitalize, SimpleJson_parseNative(response.responseText)), (() => {
                try {
                    return new FSharpResult$2(0, [Convert_fromJson(input_1, createTypeInfo(PagedDataResponse$1_$reflection(BookingSearchItem_$reflection())))]);
                }
                catch (ex) {
                    return new FSharpResult$2(1, [ex.message]);
                }
            })())) : tryParseAsApiMessage(response);
            return singleton.Return(result_1);
        });
    });
}

export function export$(query) {
    return singleton.Delay(() => {
        let req_2, req_1;
        const payload = Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(query);
        return singleton.Bind(Http_send(Http_overrideResponseType(new ResponseTypes(1, []), Http_content(new BodyContent(1, [payload]), (req_2 = ((req_1 = Http_method(new HttpMethod(1, []), Http_request(`${process.env.API_BASE_ADDRESS ? process.env.API_BASE_ADDRESS : ''}/api/v1/Bookings/Export`)), Http_header(Headers_contentType("application/json"), req_1))), Http_header(Headers_authorization(`Bearer ${getIdToken()}`), req_2))))), (_arg) => {
            const response = _arg;
            let result;
            if (response.statusCode === 200) {
                const matchValue = response.content;
                if (matchValue.tag === 1) {
                    Blob_download(matchValue.fields[0], "bookings-export.csv");
                }
                else {
                    throw new Error("Unexpected response content");
                }
                result = (new FSharpResult$2(0, [void 0]));
            }
            else {
                result = tryParseAsApiMessage(response);
            }
            return singleton.Return(result);
        });
    });
}

