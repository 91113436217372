import { Record } from "../fable_modules/fable-library.4.10.0/Types.js";
import { array_type, float64_type, record_type, int32_type, string_type } from "../fable_modules/fable-library.4.10.0/Reflection.js";
import { renderHeatmap, renderLocationGroups, renderLocations, renderShapes } from "./GoogleMaps.js";
import { singleton } from "../fable_modules/fable-library.4.10.0/List.js";

export class Options extends Record {
    constructor(ElementId, Zoom) {
        super();
        this.ElementId = ElementId;
        this.Zoom = (Zoom | 0);
    }
}

export function Options_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.Options", [], Options, () => [["ElementId", string_type], ["Zoom", int32_type]]);
}

export class Coordinates extends Record {
    constructor(Latitude, Longitude) {
        super();
        this.Latitude = Latitude;
        this.Longitude = Longitude;
    }
}

export function Coordinates_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.Coordinates", [], Coordinates, () => [["Latitude", float64_type], ["Longitude", float64_type]]);
}

export class Rectangle extends Record {
    constructor(NorthEast, SouthWest) {
        super();
        this.NorthEast = NorthEast;
        this.SouthWest = SouthWest;
    }
}

export function Rectangle_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.Rectangle", [], Rectangle, () => [["NorthEast", Coordinates_$reflection()], ["SouthWest", Coordinates_$reflection()]]);
}

export class Circle extends Record {
    constructor(Radius, Center) {
        super();
        this.Radius = Radius;
        this.Center = Center;
    }
}

export function Circle_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.Circle", [], Circle, () => [["Radius", float64_type], ["Center", Coordinates_$reflection()]]);
}

export class Polygon extends Record {
    constructor(Bounds) {
        super();
        this.Bounds = Bounds;
    }
}

export function Polygon_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.Polygon", [], Polygon, () => [["Bounds", array_type(Coordinates_$reflection())]]);
}

export class Shapes extends Record {
    constructor(Rectangles, Circles, Polygons) {
        super();
        this.Rectangles = Rectangles;
        this.Circles = Circles;
        this.Polygons = Polygons;
    }
}

export function Shapes_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.Shapes", [], Shapes, () => [["Rectangles", array_type(Rectangle_$reflection())], ["Circles", array_type(Circle_$reflection())], ["Polygons", array_type(Polygon_$reflection())]]);
}

export class Location extends Record {
    constructor(Title, Content, Latitude, Longitude) {
        super();
        this.Title = Title;
        this.Content = Content;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
    }
}

export function Location_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.Location", [], Location, () => [["Title", string_type], ["Content", string_type], ["Latitude", float64_type], ["Longitude", float64_type]]);
}

export class LocationGroup extends Record {
    constructor(Locations) {
        super();
        this.Locations = Locations;
    }
}

export function LocationGroup_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.LocationGroup", [], LocationGroup, () => [["Locations", array_type(Location_$reflection())]]);
}

export class HeatmapData extends Record {
    constructor(Latitude, Longitude, TotalShipment, Index) {
        super();
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.TotalShipment = (TotalShipment | 0);
        this.Index = (Index | 0);
    }
}

export function HeatmapData_$reflection() {
    return record_type("Fable.Elmish.CraneWW.GoogleMaps.HeatmapData", [], HeatmapData, () => [["Latitude", float64_type], ["Longitude", float64_type], ["TotalShipment", int32_type], ["Index", int32_type]]);
}

export const Interop_renderShapes = renderShapes;

export const Interop_renderLocations = renderLocations;

export const Interop_renderLocationGroups = renderLocationGroups;

export const Interop_renderHeatmap = renderHeatmap;

export function GoogleMaps_renderShapes(options, shapes, handler) {
    return singleton((dispatch) => {
        Interop_renderShapes(options, shapes, (shapes_1) => {
            dispatch(handler(shapes_1));
        });
    });
}

export function GoogleMaps_renderLocations(options, locations) {
    return singleton((dispatch) => {
        Interop_renderLocations(options, locations);
    });
}

export function GoogleMaps_renderLocationGroups(options, locationGroups) {
    return singleton((dispatch) => {
        Interop_renderLocationGroups(options, locationGroups);
    });
}

export function GoogleMaps_renderHeatmap(options, heatmapDataPoint) {
    return singleton((dispatch) => {
        Interop_renderHeatmap(options, heatmapDataPoint);
    });
}

