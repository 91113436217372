import { Loader } from "@googlemaps/js-api-loader"

const loader = new Loader({
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
    version: "weekly",
    libraries: ['drawing', 'visualization']
});

const heatmapGradientColor = [
    "rgba(255, 10, 0, 0.7)",
    "rgba(0, 174, 128, 0.7)",
    "rgba(76, 92, 163, 0.7)",
    "rgba(246, 140, 12, 0.7)",
    "rgba(223, 200, 50, 0.7)",
]

const heatmapGradientBorderColor = [
    "rgba(255, 0, 60, 1)",
    "rgba(35, 140, 88, 1)",
    "rgba(42, 63, 140, 1)",
    "rgba(246, 120, 12, 1)",
    "rgba(249, 211, 4, 1)",
]

/**
 * @typedef {Object} Options
 * @property {String} ElementId
 * @property {Number} Zoom
 */

/**
 * @typedef {Object} Coordinates
 * @property {Number} Latitude 
 * @property {Number} Longitude 
 */

/**
 * @typedef {Object} Rectangle
 * @property {Coordinates} NorthEast 
 * @property {Coordinates} SouthWest 
 */

/**
 * @typedef {Object} Circle
 * @property {Number} Radius 
 * @property {Coordinates} Center 
 */

/**
 * @typedef {Object} Polygon
 * @property {Coordinates[]} Bounds 
 */

/**
 * @typedef {Object} Shapes
 * @property {Rectangle[]} Rectangles 
 * @property {Circle[]} Circles 
 * @property {Polygon[]} Polygons 
 */

/**
 * @typedef {Object} Location
 * @property {String} Title
 * @property {String} Content
 * @property {Number} Latitude 
 * @property {Number} Longitude 
 */

/**
 * @typedef {Object} LocationGroup
 * @property {Location[]} Locations 
 */

/**
 * @typedef {Object} HeatmapData
 * @property {Number} Latitude 
 * @property {Number} Longitude 
 * @property {Number} TotalShipment 
 * @property {Number} Index 
 */

/**
 * @callback ShapesChanged
 * @param {Shapes} shapes
 * @returns {void}
 */

/**
* @param {Options} options 
* @param {Shapes} shapes
* @param {ShapesChanged} handler
*/

export function renderShapes(options, shapes, handler) {

    let [mapShapes, count, map] = [[], 0, undefined];

    setTimeout(() => {
        loader.load()
            .then(() => {
                map = new google.maps.Map(document.getElementById(options.ElementId), {
                    zoom: options.Zoom,
                    zoomControl: false,
                    //maxZoom: options.Zoom + 3,
                    //minZoom: 0,
                    streetViewControl: false,
                    fullscreenControl: false,
                    restriction: {
                        latLngBounds: { north: 80, south: -70, west: -179, east: 180 },
                        strictBounds: true
                    },
                    center: { lat: 10, lng: 180 }
                });

                if (shapes) {
                    if (!shapes.Rectangles) { shapes.Rectangles = []; }
                    if (!shapes.Circles) { shapes.Circles = []; }
                    if (!shapes.Polygons) { shapes.Polygons = []; }

                    shapes.Rectangles.forEach(rectangle => drawRectangle(rectangle));
                    shapes.Circles.forEach(circle => drawCircle(circle));
                    shapes.Polygons.forEach(polygon => drawPolygon(polygon));
                }

                const drawingManager = new google.maps.drawing.DrawingManager({
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                            google.maps.drawing.OverlayType.POLYGON,
                            google.maps.drawing.OverlayType.CIRCLE,
                            google.maps.drawing.OverlayType.RECTANGLE,
                        ],
                    },
                    polygonOptions: {
                        fillColor: 'red',
                        editable: true,
                        clickable: true,
                        draggable: true,
                    },
                    circleOptions: {
                        fillColor: 'red',
                        editable: true,
                        clickable: true,
                        draggable: true,
                    },
                    rectangleOptions: {
                        fillColor: 'red',
                        editable: true,
                        clickable: true,
                        draggable: true,
                    },
                });

                drawingManager.setMap(map);

                google.maps.event.addListener(drawingManager, 'overlaycomplete', (e) => {
                    let newShape = e.overlay;
                    newShape.type = e.type;
                    newShape.id = count++;
                    mapShapes.push(newShape);

                    drawingManager.setDrawingMode(null);

                    addModifyShapeListener(newShape);

                    google.maps.event.addListener(newShape, 'rightclick', (e) => {
                        rightClickShape(newShape, e);
                    });

                    getCities();
                });
            })
            .catch((error) => {
                console.error('Error loading Google Maps API:', error);
            });
    }, 0);

    function addModifyShapeListener(shape) {
        switch (shape.type) {
            case google.maps.drawing.OverlayType.POLYGON:
                google.maps.event.addListener(shape, 'mouseup', (event) => {
                    // Ignore right clicks since we already have an event listener for them.
                    if (event?.domEvent?.button !== 2) {
                        getCities()
                    }
                });
                break;
            case google.maps.drawing.OverlayType.RECTANGLE:
                google.maps.event.addListener(shape, 'bounds_changed', (event) => { getCities() });
                break;
            case google.maps.drawing.OverlayType.CIRCLE:
                google.maps.event.addListener(shape, 'center_changed', (event) => { getCities() });
                google.maps.event.addListener(shape, 'radius_changed', (event) => { getCities() });
                break;
        }
    }

    function rightClickShape(shape, event) {
        switch (shape.type) {
            case google.maps.drawing.OverlayType.POLYGON:
                if (event.vertex !== undefined) {
                    let path = shape.getPaths().getAt(event.path);
                    path.removeAt(event.vertex);
                    if (path.length < 3) {
                        removeShape(shape);
                    }
                } else {
                    removeShape(shape);
                }
                break;
            default:
                removeShape(shape);
        }
        getCities();
    }


    function removeShape(shape) {
        mapShapes = mapShapes.filter((d) => d.id !== shape.id);
        shape.setMap(null);
    }

    function getCities() {
        const selectedShapes = {
            Rectangles: mapShapes.filter((shape) => shape.type === google.maps.drawing.OverlayType.RECTANGLE).map((shape) =>
            ({
                NorthEast: { Latitude: shape.bounds.getNorthEast().lat(), Longitude: shape.bounds.getNorthEast().lng() },
                SouthWest: { Latitude: shape.bounds.getSouthWest().lat(), Longitude: shape.bounds.getSouthWest().lng() }
            })),
            Circles: mapShapes.filter((shape) => shape.type === google.maps.drawing.OverlayType.CIRCLE).map((shape) =>
            ({
                Radius: shape.radius,
                Center: { Latitude: shape.center.lat(), Longitude: shape.center.lng() }
            })),
            Polygons: mapShapes.filter((shape) => shape.type === google.maps.drawing.OverlayType.POLYGON).map((shape) => {
                const bounds = [];
                const polys = shape.getPath();
                for (var i = 0; i < polys.length; i++) {
                    bounds.push({
                        Latitude: polys.getAt(i).lat(),
                        Longitude: polys.getAt(i).lng(),
                    });
                }
                return { Bounds: bounds };
            })
        };

        if (handler) {
            handler(selectedShapes);
        }
    }

    /**
     * @param {Rectangle} rectangle
     */
    function drawRectangle(rectangle) {
        const latlngBounds = new google.maps.LatLngBounds();
        const shape = new google.maps.Rectangle({
            id: count++,
            type: google.maps.drawing.OverlayType.RECTANGLE,
            bounds: {
                north: rectangle.NorthEast.Latitude,
                east: rectangle.NorthEast.Longitude,
                south: rectangle.SouthWest.Latitude,
                west: rectangle.SouthWest.Longitude
            },
            fillColor: 'red',
            editable: true,
            draggable: true
        });
        latlngBounds.extend(new google.maps.LatLng(rectangle.NorthEast.Latitude, rectangle.NorthEast.Longitude));
        latlngBounds.extend(new google.maps.LatLng(rectangle.SouthWest.Latitude, rectangle.SouthWest.Longitude));

        onShapeDrawn(latlngBounds, shape);
    }

    /**
     * @param {Circle} circle
     */
    function drawCircle(circle) {
        const latlngBounds = new google.maps.LatLngBounds();
        const shape = new google.maps.Circle({
            id: count++,
            type: google.maps.drawing.OverlayType.CIRCLE,
            center: new google.maps.LatLng(circle.Center.Latitude, circle.Center.Longitude),
            radius: circle.Radius,
            fillColor: 'red',
            editable: true,
            draggable: true
        });
        latlngBounds.extend(new google.maps.LatLng(circle.Center.Latitude, circle.Center.Longitude));

        onShapeDrawn(latlngBounds, shape);
    }

    /**
     * @param {Polygon} polygon
     */
    function drawPolygon(polygon) {
        const latlngBounds = new google.maps.LatLngBounds();
        const shape = new google.maps.Polygon({
            id: count++,
            type: google.maps.drawing.OverlayType.POLYGON,
            paths: polygon.Bounds.map((coordinates) => new google.maps.LatLng(coordinates.Latitude, coordinates.Longitude)),
            fillColor: 'red',
            editable: true,
            draggable: true
        });
        polygon.Bounds.forEach((coordinates) => {
            latlngBounds.extend(new google.maps.LatLng(coordinates.Latitude, coordinates.Longitude));
        });

        onShapeDrawn(latlngBounds, shape);
    }

    function onShapeDrawn(latlngBounds, shape) {
        addModifyShapeListener(shape);

        google.maps.event.addListener(shape, 'rightclick', (e) => {
            rightClickShape(shape, e);
        });

        mapShapes.push(shape);
        try {
            shape.setMap(map);
            map.setCenter(latlngBounds.getCenter());
        }
        catch (error) {
            onsole.error('Failed to associate map to shape:', error);
        }
    }
}

/**
 * @param {Options} options 
 * @param {Location[]} locations
 */
export function renderLocations(options, locations) {

    setTimeout(() => {
        loader.load().then(() => {

            const element = document.getElementById(options.ElementId);

            if (!element) {
                return;
            }

            element.style.height = "100%";

            const map = new google.maps.Map(document.getElementById(options.ElementId), {
                zoom: options.Zoom,
                zoomControl: false,
                //maxZoom: options.Zoom + 3,
                //minZoom: 0,
                streetViewControl: false,
                fullscreenControl: false,
                restriction: {
                    latLngBounds: { north: 80, south: -70, west: -179, east: 180 },
                    strictBounds: true
                }
                ,
                center: { lat: 10, lng: 180 }
            });

            const hasLocations = locations && locations.length;

            if (!hasLocations) {
                return;
            }

            const infoWindow = new google.maps.InfoWindow({
                content: "",
                disableAutoPan: true,
            });

            const bounds = new google.maps.LatLngBounds();

            locations.forEach(location => {
                const position = new google.maps.LatLng(location.Latitude, location.Longitude);

                const marker = new google.maps.Marker({
                    map: map,
                    position: position,
                    title: location.Title
                });

                if (location.Content) {
                    marker.addListener("click", () => {
                        infoWindow.setContent(location.Content);
                        infoWindow.open(map, marker);
                    });
                }

                bounds.extend(position);
            });

            new google.maps.Polyline({
                map: map,
                path: locations.map(s => new google.maps.LatLng(s.Latitude, s.Longitude)),
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
            });

            map.fitBounds(bounds);
        });
    }, 0);

    window.addEventListener('hashchange', handleHashChange);

}

/**
 * @param {Options} options 
 * @param {LocationGroup[]} locationGroups
 */
export function renderLocationGroups(options, locationGroups) {

    setTimeout(() => {
        loader.load().then(() => {
            const worldBounds = { north: 80, south: -70, west: -179, east: 180 }
            const element = document.getElementById(options.ElementId);

            if (!element) {
                return;
            }

            element.style.height = "100%";

            const map = new google.maps.Map(document.getElementById(options.ElementId), {
                zoom: options.Zoom,
                zoomControl: false,
                //maxZoom: options.Zoom + 3,
                //minZoom: 0,
                streetViewControl: false,
                fullscreenControl: false,
                restriction: {
                    latLngBounds: { north: 80, south: -70, west: -179, east: 180 },
                    strictBounds: true
                },
                center: { lat: 10, lng: 180 }
            });
            const hasGroups = locationGroups && locationGroups.length;
            map.fitBounds(worldBounds)
            if (!hasGroups) {
                return;
            }

            const infoWindow = new google.maps.InfoWindow({
                content: "",
                disableAutoPan: true,
            });

            const bounds = new google.maps.LatLngBounds();

            locationGroups.forEach(locationGroup => {
                locationGroup.Locations.forEach(location => {
                    const position = new google.maps.LatLng(location.Latitude, location.Longitude);

                    const marker = new google.maps.Marker({
                        map: map,
                        position: position,
                        title: location.Title
                    });

                    if (location.Content) {
                        marker.addListener("click", () => {
                            infoWindow.setContent(location.Content);
                            infoWindow.open(map, marker);
                        });
                    }

                    bounds.extend(position);
                });



                new google.maps.Polyline({
                    map: map,
                    path: locationGroup.Locations.map(s => new google.maps.LatLng(s.Latitude, s.Longitude)),
                    geodesic: true,
                    strokeColor: "#FF0000",
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });
            });


            const center = bounds.getCenter();
            map.setCenter(center);
            map.setZoom(15);
            //map.fitBounds(bounds);
        });
    }, 0);
}

/**
 * @param {Options} options 
 * @param {HeatmapData[]} heatmapDataPoint
 */
export function renderHeatmap(options, heatmapDataPoint) {

    setTimeout(() => {
        loader.load().then(() => {
            const worldBounds = { north: 80, south: -70, west: -179, east: 180 }
            const element = document.getElementById(options.ElementId);

            if (!element) {
                return;
            }

            element.style.height = "100%";

            const map = new google.maps.Map(document.getElementById(options.ElementId), {
                zoom: options.Zoom,
                zoomControl: true,
                mapTypeControl: false,
                //maxZoom: options.Zoom + 3,
                minZoom: 0,
                streetViewControl: false,
                fullscreenControl: true,
                restriction: {
                    latLngBounds: { north: 80, south: -70, west: -179, east: 180 },
                    strictBounds: true
                }
            });

            const hasGroups = heatmapDataPoint && heatmapDataPoint.length;

            map.fitBounds(worldBounds)
            if (!hasGroups) {
                return;
            }
            
            const bounds = new google.maps.LatLngBounds();

            const maxDeliveryCount = heatmapDataPoint[0].TotalShipment

            heatmapDataPoint.forEach((city) => {

                const fillColor = heatmapGradientColor[city.Index]
                const borderColor = heatmapGradientBorderColor[city.Index]

                var maxRadiusBar = 60
                var calCulativeRadius = maxRadiusBar - 5

                if (maxDeliveryCount <= 10)
                {
                    maxRadiusBar = 30
                    calCulativeRadius = maxRadiusBar - 5
                }

                const radius = Math.min(10 + ((city.TotalShipment/ maxDeliveryCount) * calCulativeRadius), maxRadiusBar)

                const gradient = [
                    "rgba(0, 255, 255, 0)",
                    "rgba(10, 255, 250, 0.1)", // transparent
                    borderColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor, // color reapet is correct for make the circle more sharper and 98% of the fill color of the entire circle
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor,
                    fillColor
                ]
                const heatmap = new google.maps.visualization.HeatmapLayer({
                    data: [new google.maps.LatLng(city.Latitude, city.Longitude)],
                    gradient: gradient,
                    radius: radius,
                    opacity: 0.9
                })
                heatmap.setMap(map)
            })

            const center = bounds.getCenter();
            map.setCenter(center);
            map.setZoom(15);

        });
    }, 0);
}

function handleHashChange() {

    // is-hidden class needs to be appended to this div
    const googleMapElement = document.querySelector(':is(div)[style*="background-color: rgb(229, 227, 223)"]');

    if (googleMapElement) {
        googleMapElement.classList.add('is-hidden');
    }

    window.removeEventListener('hashchange', handleHashChange);
}